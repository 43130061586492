<template>
  <a-card :bordered="false">
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="newProcess" v-action:add>新建流程</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
      :showPagination="false">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="editFrom(record)">编辑</a>
          <a-divider type="vertical"/>
          <a v-action:add @click="stopFrom(record)">{{ record.is_stop ? '启用' : '停用' }}</a>
          <a-divider type="vertical" v-show="record.is_stop"/>
          <a-popconfirm
            v-action:add
            title="确定删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="moveFrom(record)"
            v-if="record.is_stop"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import {
   updateForm, getForms
} from '@/api/design'

export default {
  name: 'FormsPanel',
  components: { STable },
  data () {
    return {
      moveSelect: '',
      visible: false,
      loading: false,
      pageNum: 1,
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '审批名称',
          dataIndex: 'template_name',
          ellipsis: true
        },
        {
          title: '所属分类',
          dataIndex: 'settings',
          align: 'center',
          customRender: (text) => JSON.parse(text)['notify']['types']['name']
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '更新时间',
          dataIndex: 'last_update',
          width: 200
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return getForms().then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  methods: {
    newProcess (groupId) {
      // this.$store.commit('setTemplate', this.getTemplateData())
      this.$store.commit('setIsEdit', false)
      this.$router.push('/users/approval/templates/create?groupId=' + 1)
    },
    updateForm (item, type) {
      const data = {}
      if (type === 'using') {
        data.is_stop = false
      } else if (type === 'stop') {
        data.is_stop = true
      } else if (type === 'delete') {
        data.is_delete = true
      }
      updateForm(data, item.id).then(res => {
        console.log(res)
        this.$refs.table.refresh()
      }).catch(err => {
        console.log(err)
      })
    },
    getTemplateData (data, group) {
      return data
    },
    editFrom (item) {
      console.log(item)
      this.$router.push('/users/approval/templates/create?code=' + item.id)
    },
    stopFrom (item) {
      const tip = item.is_stop ? ' 是否启用？' : ' 停用后将不会被使用，您可以再次启用或者删除它，是否继续?'
      this.$confirm(`<span style="font-weight: bold">${item.template_name}</span>` + tip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.updateForm(item, (item.is_stop ? 'using' : 'stop'))
      })
    },
    moveFrom (item) {
      if (item.is_stop) {
        this.updateForm(item, 'delete')
      }
    }
  }
}
</script>

<style lang="less" scoped>
body {
  background: #ffffff !important;
}

.undrag {
  background: #ffffff !important;
}

.from-panel {
  padding: 50px 100px;
  min-width: 500px;
  background: #ffffff;

  /deep/ .from-title {
    div {
      float: right;

      .el-button {
        border-radius: 15px;
      }
    }
  }

  //height: 100vh;
}

.choose {
  background: #e9ebee;
}

.form-group {
  margin: 20px 0;
  padding: 5px 0px;
  border-radius: 10px;
  //border: 1px solid #d3d3d3;
  box-shadow: 1px 1px 10px 0 #d2d2d2;

  &:hover {
    box-shadow: 1px 1px 12px 0 #b3b3b3;
  }

  .form-group-title {
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #d3d3d3;

    .el-icon-rank {
      display: none;
      cursor: move;
    }

    &:hover {
      .el-icon-rank {
        display: inline-block;
      }
    }

    div {
      display: inline-block;
      float: right;
    }

    span:first-child {
      margin-right: 5px;
      font-size: 15px;
      font-weight: bold;
    }

    span:nth-child(2) {
      color: #656565;
      font-size: small;
      margin-right: 10px;
    }

    /deep/ .el-button {
      color: #404040;
      margin-left: 20px;

      &:hover {
        color: #2b2b2b;
      }
    }
  }

  .form-group-item:nth-child(1) {
    border-top: none !important;
  }

  .form-group-item {
    color: #3e3e3e;
    font-size: small;
    padding: 0 0;
    margin: 0 20px;
    height: 60px;
    position: relative;
    line-height: 60px;
    border-top: 1px solid #d3d3d3;

    div {
      display: inline-block;
    }

    i {
      border-radius: 10px;
      padding: 7px;
      font-size: 20px;
      color: #ffffff;
      margin-right: 10px;
    }

    div:nth-child(1) {
      float: left;
      width: 280px;
    }

    div:nth-child(2) {
      position: absolute;
      color: #7a7a7a;
      font-size: 12px;
      left: 300px;
      max-width: 300px;
      overflow: hidden;
    }

    div:nth-child(3) {
      position: absolute;
      right: 30%;
    }

    div:nth-child(4) {
      float: right;
    }
  }
}

@media screen and (max-width: 1000px) {
  .desp {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .from-panel {
    padding: 50px 10px;
  }
}
</style>
